import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query Contents {
    allMdx {
      nodes {
        frontmatter {
          title
        }
        slug
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ul style={{
      listStyle: 'none',
      paddingLeft: 0
    }}>
    {props.data.allMdx.nodes.map(({
        frontmatter,
        slug
      }) => <li key={slug}>
            <a href={`/${slug}`}>{frontmatter.title}</a>
        </li>)}
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      